import React from "react";
import Banner from "../Banner/Banner";
import logo from "../../assets/Figma/FavCon 1.png";
import Footer from "./Footer";
const Home = () => {
  return (
    <div class="intro-page">
      <Banner />
      <Footer logo={logo}/>
    </div>
  );
};

export default Home;
