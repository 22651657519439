
import React from 'react';
import ReactDOM from 'react-dom';

// import '../src/assets/css/index.css';
// import '../src/assets/css/responshiv.css'
import App from './App';
import { BrowserRouter } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("ownerscope")
);