import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import React from "react";

import users from "../../../assets/Figma/userVector.png";

import moreinsight from "../../../assets/Figma/moreinsight.png";

import setcore from "../../../assets/Figma/setcore.png";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
const Menu = () => {
  const navigate = useNavigate();

  // const SetCore = () => {
  //   navigate("/SetCore");
  // };

  const MoreInsight = () => {
    navigate("/MoreInsight");
  };
  // const InviteCircle = () => {
  //   navigate("/InviteCircle");
  // };
  return (
    <>
      <BottomNavigation showLabels>
        <a href="#Invite_Circle">
          <BottomNavigationAction
            label="Invite Circle"
            icon={
              <img
                className="footer-icon filter-blue"
                src={users}
                alt="MoreInsight"
              />
            }
          />
        </a>
        <a href="#setcore" className="setcore">
          <BottomNavigationAction
            label="Set Core"
            icon={
              <img
                className="footer-icon filter-blue"
                src={setcore}
                alt="SetCore"
              />
            }
          />
        </a>
        <a
          onClick={() => {
            Swal.fire({
              text: `Ownerscope helps with that. From the topics below, select 3 that are most relevant to you when considering home ownership.`,

              confirmButtonText: "OK",
            }).then(function () {
              window.scrollTo(0, document.getElementById("setcore"));
            });
          }}
        >
          <BottomNavigationAction
            label="More Insight"
            icon={
              <img
                className="footer-icon filter-blue"
                src={moreinsight}
                alt="MoreInsight"
              />
            }
          />
        </a>
      </BottomNavigation>
    </>
  );
};

export default Menu;
