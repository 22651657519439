import axios from "axios";
const api = axios.create({
  //server

  baseURL: "https://api.ownerscope.com/",
  imgUrl: "https://admin.ownerscope.com/adminpanel/QuestionImage/",
  headers: {
    "Content-Type": "application/json",
  },
});

export default api;
