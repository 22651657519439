import React from "react";
import { Link } from "react-router-dom";

const Footer = (props) => {
 
  return (
      <footer style={{ marginLeft:`${props.marginLeft}`}} className='footer'>
        <div className="text-light">
        {props.logo?<img src={props.logo} alt="logo" className="footer_logo hideDesktop"/>:null} 
          © Ownerscope Worldwide LLC &nbsp; | &nbsp; All rights reserved &nbsp; | &nbsp; <span><a href="/privacy-policy" target="_blank">  Privacy Policy   </a> & <a href="/term-settings" target="_blank"> Terms of Use</a></span>
        </div>
      </footer> 
  );
};

export default Footer;
