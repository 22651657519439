import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";

import Button from "@mui/material/Button";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import imgPath1 from "../../assets/Figma/1stHome.png";
import imgPath2 from "../../assets/Figma/HappyOwners1.png";
import imgPath3 from "../../assets/Figma/WeClosed.png";
import logo from "../../assets/Figma/bannerLogo.jpg";
import { useNavigate } from "react-router-dom";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function Banner() {
  let navigate = useNavigate();
  let GoQuestion = () => {
    navigate(`/Question`);
  };
  const images = [
    {
      label: "What’s your Ownerscope?",
      Details:
        "This 3-minute personality quiz will bring you and your circle closer to owning your next property.",
      imgPath: imgPath1,
      logo: logo,
      button: (
        <Button
          variant="contained"
          size="large"
          className="rectangle"
          style={{ color: "#644200" }}
          onClick={GoQuestion}
        >
          get started
        </Button>
      ),
    },

    {
      label: "What’s your Ownerscope?",
      Details:
        "Owning or renting are influenced by your social circles and your personality type -- we call this your Ownerscope.",
      imgPath: imgPath2,
      logo: logo,
      button: (
        <Button
          variant="contained"
          size="large"
          className="rectangle"
          style={{ color: "#644200" }}
          onClick={GoQuestion}
        >
          get started
        </Button>
      ),
    },
    {
      label: "What’s your Ownerscope?",
      Details: "Everyone has an Ownerscope. What's yours?",
      imgPath: imgPath3,
      logo: logo,
      button: (
        <Button
          variant="contained"
          size="large"
          className="rectangle"
          style={{ color: "#644200" }}
          onClick={GoQuestion}
        >
          get started
        </Button>
      ),
    },
  ];
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <div class="slider-wrapper">
      <div className="slider-banner">
      <AutoPlaySwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        interval="5000"
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {images.map((step, index) => (
          <div key={index} className="slide-box">
            {Math.abs(activeStep - index) <= 2 ? (
              
              <Box
                  component="img"
                  sx={{
                    display: "block",

                    overflow: "hidden",
                    width: "100%",
                  }}
                  src={step.imgPath}
                  alt={step.label}
              />
              
             
            ) : null}

            <img src={images[activeStep].logo} alt="logo" className="slider_logo" />

          </div>
          
        ))}
        
      </AutoPlaySwipeableViews>
      </div>

      <div className="slider-content">
        <h1 className="slider_lebel">{images[activeStep].label}</h1>
        <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
            className={
              theme.direction === "rtl" ? "ArrowLeftIcon" : "ArrowRightIcon"
            }
          >
            {/* {activeStep === 1 ? "Next" : "get started"} */}
            
            {theme.direction === "rtl" ? <ArrowLeftIcon /> : <ArrowRightIcon />}
          </Button>
        }
        backButton={
          activeStep === 2 ? // <Button
          //   variant="contained"
          //   size="large"
          //   className="rectangle  hide_mobile"
          //   style={{ color: "#644200" }}
          //   onClick={GoQuestion}
          // >
          //   get started
          // </Button>
          null : (
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
              className={
                theme.direction === "rtl" ? "ArrowRightIcon" : "ArrowLeftIcon"
              }
            >
              {theme.direction === "rtl" ? (
                <ArrowRightIcon />
              ) : (
                <ArrowLeftIcon />
              )}
               
            </Button>
          )
        }
      />
        <p className="slider_details">{images[activeStep].Details}</p>
        <p className="mt-4 mb-1 slider_button"> {images[activeStep].button}</p>
      </div>

     
    </div>
  );
}

export default Banner;
