import React ,{Suspense, lazy } from 'react';

import {
  Routes,
  Route
} from "react-router-dom";

import Home from './Component/Pages/Home';
import InviteCircle from './Component/Pages/InviteCircle';
import Menu from './Component/Pages/Menu/Menu';
import MoreInsight from './Component/Pages/MoreInsight';
// import QustionStep from './Component/Pages/QustionStep';
import SetCore from './Component/Pages/SetCore';
import logo from "../src/assets/Figma/loader.gif";
import ScrollToTop from './Component/scrollToTop';
import PrivacyPolicy from './Component/Pages/privacy-policy';
import TermSetting from './Component/Pages/term-settings';
const QustionStep = React.lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import('./Component/Pages/QustionStep')), 5000);
  });
});

function App() {
  // const QustionStep = lazy(() =>import('./Component/Question'), 500);
  return (
    <>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/term-settings" element={<TermSetting />} />
      <Route path="/Question" element={<> <Suspense fallback={ <div className="">
        <img
          src={logo}
          alt={"logo"}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "https://admin.ownerscope.com/QuestionImage/WeClosed.jpg";
          }}
          className="text-center logoLoder"
        />
      </div>}>
        <QustionStep />
        
        </Suspense> </>} />
      
      {/* <Route path="/InviteCircle" element={<> <InviteCircle /> <Menu /></>} />
      <Route path="/SetCore" element={<> <SetCore /> <Menu /> </>} /> */}
      <Route path="/MoreInsight" element={<> <MoreInsight /></>} />
      <Route path="*" element={<Home />} />
      
    </Routes>
  </>
  );
}

export default App;
