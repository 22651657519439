import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import RightDrawer from './Drawer';
function appBarLabel(label) {
  return (
    <Toolbar>
      
      <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
        {label}
      </Typography>
      <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
        <RightDrawer/>
      </IconButton>
    </Toolbar>
  );
}

const darkTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#224388',
    },
  },
});

export default function AppMenu() {
  return (
    <Stack spacing={2} sx={{ flexGrow: 1 }}>
      <ThemeProvider theme={darkTheme}>
        <AppBar position="static" >
          {appBarLabel('Ownerscope')}
        </AppBar>

      </ThemeProvider>
    </Stack>
  );
}
